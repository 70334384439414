<template>
  <v-row>
    <v-col cols="12" class="d-flex align-center">
      <h2>{{ $t('redeem_reward.head_add') }}</h2>
      <v-divider class="mx-3" vertical />
      <v-breadcrumbs :items="breadcrumbsItems" divider=">>" />
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title>{{ $t('redeem_reward.head_add_list') }} </v-card-title>

        <v-form ref="form" v-model="formValid" @submit.prevent="submitForm">
          <v-divider class="mx-3" />
          <!-- Title Input -->
          <v-card-actions>
            <v-row class="d-flex flex-column">
              <v-row class="ma-0">
                <v-col cols="12" xs="3" md="3" xl="6">
                  <v-text-field v-model="formData.name" :label="$t('redeem_reward.header1')" outlined
                    dense></v-text-field>
                </v-col>
                <v-col cols="12" xs="6" md="6" xl="6">
                  <v-text-field v-model="formData.detail" :label="$t('redeem_reward.header2')" outlined
                    dense></v-text-field>
                </v-col>
              </v-row>
              <v-row class="ma-0">
                <v-col cols="12" xs="3" md="3" xl="6">
                  <v-select v-model="formData.reward_type" :items="rewardTypes" item-text="rewardTypes" item-value="value"
                    :label="$t('redeem_reward.header4')" outlined dense></v-select>
                </v-col>
                <v-col cols="12" xs="3" md="3" xl="3">
                  <v-text-field v-model="formData.reward_amount" :label="$t('redeem_reward.header5')" outlined
                    dense></v-text-field>
                </v-col>
              </v-row>
              <v-row class="ma-0">
                <v-col cols="12" xs="3" md="6" xl="6">
                  <div class="upload-container">
                    <input class="upload-banner" v-model="filename" type="text" placeholder="รูปภาพ"
                      messages="รูปภาพ (700x200)" readonly />
                    <button class="clear-btn" v-if="filename" @click="clearValue('upload')">X</button>
                    <input type="file" @change="uploadFile('upload')" accept="image/*" placeholder="อัพโหลดรูปโลโก้"
                      ref="upload" style="width: 200px; display: none" />
                    <v-btn text color="primary" class="btn-custom" @click="$refs.upload.click()">อัพโหลด</v-btn>
                    <label style="font-size: 0.8rem">(700x200 Pixels)</label>
                  </div>
                </v-col>
              </v-row>
              <v-row class="ma-0">

                <v-col cols="12" xs="3" md="3" xl="3">
                  <v-text-field v-model="formData.turn" :label="$t('redeem_reward.header6')" outlined
                    dense></v-text-field>
                </v-col>
                <v-col cols="12" xs="3" md="3" xl="3">
                  <v-text-field v-model="formData.reward_count" :label="$t('redeem_reward.header9')" outlined
                    dense></v-text-field>
                </v-col>
                <v-col cols="12" xs="3" md="3" xl="3">
                  <v-text-field v-model="formData.index" :label="$t('redeem_reward.header12')" outlined
                    dense></v-text-field>
                </v-col>
              </v-row>
              <v-row class="ma-0">
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-select v-model="formData.redeem_type" :items="redeemTypes" item-text="redeemTypes" item-value="value"
                    :label="$t('redeem_reward.header7')" outlined dense></v-select>
                </v-col>
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-text-field v-model="formData.redeem_amount" :label="$t('redeem_reward.header8')" outlined
                    dense></v-text-field>
                </v-col>
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-select v-model="formData.is_partner" :items="status" item-text="is_partner" item-value="value"
                    :label="$t('redeem_reward.header13')" outlined dense></v-select>
                </v-col>
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-select v-model="formData.status" :items="status" item-text="status" item-value="value"
                    :label="$t('redeem_reward.header10')" outlined dense :rules="formRule.type"></v-select>
                </v-col>
              </v-row>
            </v-row>
          </v-card-actions>

          <v-card-actions>
            <v-spacer />
            <v-btn color="warning" @click="$router.push({ name: 'redeemreward' })">
              <v-icon>mdi-arrow-left</v-icon>
              <span class="px-3">{{ $t('back') }}</span>
            </v-btn>
            <v-btn color="success" class="px-3" @click="submitForm" :loading="loading">
              <v-icon small>mdi-square-edit-outline</v-icon>
              <span color="primary" class="px-3">{{ $t('redeem_reward.create') }}</span></v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import StaffLimitAccessPage from '@/components/staff/StaffLimitAccessPage.vue'
import store from '@/store'
import moment from 'moment'

export default {
  components: {
    StaffLimitAccessPage,
  },
  data() {
    return {
      loading: false,
      formValid: true,
      formRule: {
        bonusAmount: [
          value => !!value || this.$t('redeem_reward.alert3'),
          value => /^\d+$/.test(value) || this.$t('redeem_reward.alert4'),
        ],
        multiplyTurnOver: [value => (value !== '' && value !== null && value !== undefined) || this.$t('redeem_reward.alert5')],
        type: [value => !!value || this.$t('redeem_reward.alert6')],
        status: [value => !!value || this.$t('redeem_reward.alert7')],
        amount: [value => !!value || this.$t('redeem_reward.alert3'), value => /^\d+$/.test(value) || this.$t('redeem_reward.alert4')],
      },
      formData: {
        name: '',
        detail: '',
        image_url: '',
        reward_type: '',
        reward_amount: 0,
        redeem_type: 'point',
        redeem_amount: 100,
        reward_count: 0,
        reward_remain: 0,
        turn: 0,
        status: null,
        is_partner: 'false',
        index: 0
      },
      rewardTypes: ['money', 'item'],
      redeemTypes: ['point'],
      status: ['true', 'false'],
      filename: '',

      breadcrumbsItems: [
        {
          text: this.$t('redeem_reward.head'),
          disabled: false,
          href: '/redeemreward',
        },
        {
          text: this.$t('redeem_reward.head_add'),
          disabled: true,
          href: '',
        },
      ],

      acceptItems: [
        {
          text: this.$t('yes'),
          value: 'yes',
        },
        {
          text: this.$t('not'),
          value: 'no',
        },
      ],
      roles: [],
    }
  },
  async created() {
    this.addLogPage()
  },
  methods: {
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await fetch('https://jsonip.com/').then(res =>
          res.json().then(
            el =>
            (data = {
              ip: el.ip,
              name: 'Redeem Reward',
              url: window.location.href,
              detail: 'สร้าง Redeem Reward',
              admin: userSystem.name ? userSystem.name : '' ? userSystem.name : '',
            }),
          ),
        )
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    submitForm() {
      if (this.$refs.form.validate()) {
        this.$swal
          .fire({
            icon: 'warning',
            title: this.$t('alert'),
            text: this.$t('redeem_reward.alert_create'),
            showCancelButton: true,
            confirmButtonText: this.$t('confirm'),
            cancelButtonText: this.$t('cancel'),
          })
          .then(async action => {
            if (action.isConfirmed) {
              await this.addRedeemRewardData()
            }
          })
      }
    },
    async addRedeemRewardData() {
      this.loading = true
      this.formData.redeem_amount = parseInt(this.formData.redeem_amount)
      this.formData.reward_amount = parseInt(this.formData.reward_amount)
      this.formData.turn = parseInt(this.formData.turn)
      this.formData.reward_count = parseInt(this.formData.reward_count)
      this.formData.reward_remain = parseInt(this.formData.reward_count)
      this.formData.index = parseInt(this.formData.index)

      try {
        let rs = await this.$store.dispatch('addRedeemReward', this.formData)

        if (rs.id) {
          this.$swal.fire(`${this.$t('redeem_reward.create_success1')} ${this.formData.title} ${this.$t('redeem_reward.create_success2')}`, '', 'success').then(action => {
            this.$router.push({ name: 'redeemreward' })
          })
        } else {
          this.$swal.fire(this.$t('redeem_reward.create_fail2'), '', 'error')
        }
        // this.$refs.form.reset()
      } catch (e) {
        if (
          !e.message.includes('Unexpected token') ||
          !e?.includes('Unexpected token') ||
          !e.message.includes('Unexpected identifier')
        ) {
          this.$swal.fire({
            icon: 'error',
            title: e,
          })
        }
      }

      this.loading = false
    },
    async uploadFile(type) {
      this.$swal.fire({
        html: this.$t('processing'),
        timerProgressBar: true,
        didOpen: () => {
          this.$swal.showLoading()
        },
      })

      let dataFile
      dataFile = this.$refs.upload.files[0]

      var bodyFormData = new FormData()
      bodyFormData.append('name', type)
      bodyFormData.append('file', dataFile)
      let dataRes

      dataRes = await this.$store.dispatch('putSettingUpload', bodyFormData)
      console.log(dataRes, dataRes.data.file_url);

      if (dataRes) {
        this.$swal.fire(this.$t('upload_complete'), '', 'success')
        this.filename = dataRes.data.file_url ? dataRes.data.file_url : ''
        this.formData.image_url = dataRes.data.file_url ? dataRes.data.file_url : ''
      } else {
        this.$swal.fire(this.$t('upload_fail'), '', 'error')
      }
    },
    clearValue(val) {
      this.formData.image_url = ''
      this.filename = ''
    },
  },
}
</script>

<style scoped>
.btn-custom {
  background: #7367f0;
  color: white !important;
}

.upload-container {
  width: 100%;
}

.upload-container input {
  height: 39px;
  width: 77%;
  border: 1px solid rgb(194, 194, 194);
  border-radius: 5px;
  padding-left: 15px;
  font-size: 14px;
  font-weight: 400;
  color: rgb(138, 138, 138);
}

.upload-container input::placeholder {
  color: rgb(139, 139, 139);
  font-size: 13px;
}

.upload-banner:hover+.clear-btn {
  display: block !important;
}

.clear-btn {
  display: none;
  height: 25px;
  width: 25px;
  z-index: 5;
  position: absolute;
  border: 1px solid gray;
  border-radius: 50%;
  background: white;
  margin: -33px 0 0 -15px;
}

.clear-btn:hover {
  display: block !important;
}

/* btn-custom */
@media (max-width: 550px) {
  .upload-container input {
    width: 73%;
  }

  .btn-custom {
    width: 80px !important;
    min-width: 80px !important;
  }

  .btn-upload-logo {
    margin-top: -50px !important;
  }
}
</style>
